(function ($) {
  'use strict';

  /**
   * Gallery Slider
  */
  mainGallerySlider();
  function mainGallerySlider() {
    $('.js-mainGallerySlider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      arrows: false,
      dots: true,
      swipe: true,
      fade: true,
      speed: 1300,
      autoplaySpeed: 2500,
    });
  }

  GallerySlider();
  function GallerySlider() {
    $('.js-gallerySlider').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      dots: true,
      swipe: true,
      fade: true,
      speed: 1300,
      autoplaySpeed: 2500,
    });
  }

  /**
   * Gallery Search
  */
  ClickSearchMenu();
  function ClickSearchMenu() {
    $('#search-menu-btn').on('click', function() {
      $('#search-menu').fadeToggle(500);
      $('body').append('<div id="js-search-overlay" class="overlay__Gallery"></div>');
      ClickOverlayClear();
    });
  }
  function ClickOverlayClear() {
    $('#js-search-overlay').on('click', function() {
      $(this).remove();
      $('#search-menu').fadeToggle(500);
    });
  }

  ClickSearchClear();
  function ClickSearchClear() {
    $('.js-select-all').on('click', function() {
      $('input[name="category[]"]').prop('checked', false);
    });
    $('.js-select-cat').on('click', function() {
      $('.js-select-all').prop('checked', false);
    });
  }

  /**
   * Gallery Title
  */
  LeftFadeInTitle();
  function LeftFadeInTitle() {
      const titleWidth = $('.mv__Title').width() + 64;
      $('.mv__Border').css({
        width: titleWidth + 'px',
      })

      const companyWidth = $('.mv__Company').width();
      $('.mv__Company-border').css({
        width: companyWidth + 'px',
      })

      $('.mv__Company-border').animate({
        left: '0',
      }, 2500, 'easeOutQuad',);
      $('.mv__Title').animate({
        left: '0',
      }, 1500, function() {
        $('.mv__Border').animate({
          left: '0',
        }, 1000);
        $('.mv__Company-text').animate({
          left: '0',
        }, 1200, function() {
          $('.mv__Bg').slideDown();
        });
      });
  }

})(jQuery);